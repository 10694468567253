import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Search.css';
import Results from '../Results/Results.js';
import SearchForm from '../SearchForm/SearchForm.js';
import FilterForm from '../FilterForm/FilterForm.js';
import useAge from '../../customHooks/useAge/useAge';

function Search() {
    const ages = useAge();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [persons, setPersons] = useState('');
    const [cities, setCities] = useState([]);
    const [city, setCity] = useState(null);
    const [age, setAge] = useState(ages[0]);

    function handleFirstNameChange(e) {
        setFirstName(e.target.value);
    }

    function handleLastNameChange(e) {
        setLastName(e.target.value);
    }

    function onSearchSubmitForm(e) {
        e.preventDefault();
        search();
    }

    function search() {
        axios.post(`http://apps.loopevo.com/apis/students/students-by-name.php`, {
            firstName: firstName,
            lastName: lastName
        })
        .then(function (response) {
            setPersons(response.data);
        })
        .catch(function (error) {
            console.log(error);
        })
    }

    function filter() {
        axios.post(`http://apps.loopevo.com/apis/students/students-filter.php`, {
            city: city,
            age: age
        })
        .then(function (response) {
            setPersons(response.data);
        })
        .catch(function (error) {
            console.log(error);
        })
    }   

    function onFilterSubmitForm(e) {
        e.preventDefault();
        filter();
    }

    function handleCityChange(e) {
        setCity(e.target.value);
    }

    function handleAgeChange(e) {
        setAge(e.target.value);
    }

    useEffect(() => {
        axios.get(`http://apps.loopevo.com/apis/locations/city.php`)
            .then(function (response) {
                const cities = response.data;
                setCities(cities);
                setCity(cities[0].id);
            })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    return (
        <div className="container">
            <div className="row mt-5">
                <div className="col-md-6 col-sm-12">
                    <SearchForm 
                        onSubmit={onSearchSubmitForm} 
                        handleFirstNameChange={handleFirstNameChange}
                        handleLastNameChange={handleLastNameChange}
                    />
                </div>
                <div className="col-md-6 col-sm-12">
                    <FilterForm 
                        onSubmit={onFilterSubmitForm}
                        handleCityChange={handleCityChange}
                        handleAgeChange={handleAgeChange}
                        cities={cities}
                        ages={ages}
                    />
                </div>
            </div>
            <div className="row mt-5">
                <Results persons={persons} />
            </div>
        </div>
    );
}

export default Search;