import React, { useEffect, useState } from 'react';
import useAge from '../../customHooks/useAge/useAge';
import axios from 'axios';

function Person(props) {
    const ages = useAge();
    const [age, setAge] = useState(ages[0]);
    const [cities, setCities] = useState([]);
    const [city, setCity] = useState(0);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [invalidFirstName, setInvalidFirstName] = useState(false);
    const [invalidLastName, setInvalidLastName] = useState(false);
    const [invalidEmail, setInvalidEmail] = useState(false);

    function handleAgeChange(e) {
        setAge(e.target.value);
    }

    function handleCityChange(e) {
        setCity(e.target.value);
    }

    function handleFirstNameChange(e) {
        setInvalidFirstName(false);
        setFirstName(e.target.value);
    }

    function handleLastNameChange(e) {
        setInvalidLastName(false);
        setLastName(e.target.value);
    }

    function handleEmailChange(e) {
        setInvalidEmail(false);
        setEmail(e.target.value);
    }

    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    function validate() {
        let validForm = true;

        if (!firstName.length) {
            setInvalidFirstName(true);
            validForm = false;
        }

        if (!lastName.length) {
            setInvalidLastName(true);
            validForm = false;
        }

        if (!validateEmail(email)) {
            setInvalidEmail(true);
            validForm = false;
        }

        return validForm;
    }

    function sendData() {
        let url = 'http://apps.loopevo.com/apis/students/add-new-student.php';
        const data = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            age: age,
            city: city
        };

        if (props.id) {
            data.id = props.id;
            url = 'http://apps.loopevo.com/apis/students/update-student.php';
        }

        axios.post(url, data)
        .then(function (response) {
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    function submit(e) {
        e.preventDefault();

        if (validate()) {
            sendData();
        }
    }

    useEffect(() => {
        axios.get(`http://apps.loopevo.com/apis/locations/city.php`)
            .then(function (response) {
                const cities = response.data;
                setCities(cities);

                if (!props.id) {
                    setCity(Number(cities[0].id));
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        if (props.id) {
            axios.post(`http://apps.loopevo.com/apis/students/student.php`, {
                id: props.id
            })
            .then(function (response) {
                const person = response.data;
                setFirstName(person.first_name);
                setLastName(person.last_name);
                setEmail(person.email);
                setAge(person.age);
                setCity(Number(person.city));
            })
            .catch(function (error) {
                console.log(error);
            });
        }
    }, [props.id]);

    return (
        <div className="container mt-5">
            {invalidFirstName &&
                <div class="alert alert-danger" role="alert">
                    Invalid first name!
                </div>
            }
            {invalidLastName &&
                <div class="alert alert-danger" role="alert">
                    Invalid last name!
                </div>
            }
            {invalidEmail &&
                <div class="alert alert-danger" role="alert">
                    Invalid email!
                </div>
            }
            <form onSubmit={submit}>
                <div className="form-group">
                    <label htmlFor="first-name">First Name:</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        id="first-name" 
                        aria-describedby="emailHelp" 
                        placeholder="Enter first name" 
                        onChange={handleFirstNameChange}
                        value={firstName} />
                </div>
                <div className="form-group">
                    <label htmlFor="last-name">Last Name:</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        id="last-name" 
                        placeholder="Enter last name" 
                        onChange={handleLastNameChange}
                        value={lastName} />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input 
                        type="email" 
                        className="form-control" 
                        id="email" 
                        placeholder="Enter email"
                        onChange={handleEmailChange}
                        value={email} />
                </div>
                <div className="form-group">
                    <label htmlFor="city">Age:</label>
                    <select 
                        className="form-control" 
                        id="age"
                        onChange={handleAgeChange}
                        value={age}>
                        {ages.length &&
                        ages.map(age => (
                            <option key={age} value={age}>
                                {age}
                            </option>
                        ))}  
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="city">City:</label>
                    <select 
                        className="form-control" 
                        id="city"
                        onChange={handleCityChange}
                        value={city}>
                        {cities.length &&
                        cities.map(city => (
                            <option key={city.id} value={city.id}>
                                {city.name}
                            </option>
                        ))}  
                    </select>
                </div>
                <button type="submit" className="btn btn-primary">Submit</button>
            </form>
        </div>
    )
}

export default Person;