import './App.css';
import Search from './components/Search/Search';
import { Router } from '@reach/router';
import PersonDetails from './components/PersonDetails/PersonDetails';
import MyNavbar from './components/Navbar/Navbar';
import Person from './components/Person/Person';

function App() {
  return (
    <>
      <MyNavbar />
      <Router>
        <Search path="/" />
        <PersonDetails path="/details/:id" />
        <Person path="/person" />
        <Person path="/person/:id" />
      </Router>
    </>
  );
}

export default App;
