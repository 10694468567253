import React from 'react';

function SearchForm({ onSubmit, handleFirstNameChange, handleLastNameChange }) {
    return (
        <form onSubmit={onSubmit}>
            <div className="form-group">
                <label htmlFor="first-name">First name:</label>
                <input 
                    type="text" 
                    className="form-control" 
                    id="first-name" 
                    aria-describedby="firstName" 
                    placeholder="Enter first name"
                    onChange={handleFirstNameChange} />
            </div>
            <div className="form-group">
                <label htmlFor="last-name">Last name:</label>
                <input 
                    type="text" 
                    className="form-control" 
                    id="last-name" 
                    placeholder="Enter last name"
                    onChange={handleLastNameChange} />
            </div>
            <button type="submit" className="btn btn-primary">Search</button>
        </form>
    )
}

export default SearchForm;