function useAge() {
    const ages = [];

    for (let i = 18; i <= 100; i++) {
        ages.push(i);
    }

    return ages;
}   

export default useAge;