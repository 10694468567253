import React, { useEffect, useState } from 'react';
import axios from 'axios';

function PersonDetails(props) {
    const [person, setPerson] = useState(null)

    useEffect(() => {
        axios.post(`http://apps.loopevo.com/apis/students/student.php`, {
            id: props.id
        })
        .then(function (response) {
            setPerson(response.data);
        })
        .catch(function (error) {
            console.log(error);
        });
    }, [props.id]);

    if (!person) {
        return (
            <div className="container">
                <div className="row">
                    Data is loading...
                </div>
            </div>
        );
    }

    return (
        <div className="container">
            <div className="row">
                <div className="d-flex justify-content-center">
                    <ul>
                        <li><strong>First Name:</strong> {person.first_name}</li>
                        <li><strong>Last Name:</strong> {person.last_name}</li>
                        <li><strong>Email:</strong> {person.email}</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}   

export default PersonDetails;