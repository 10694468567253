import React, { useState, useEffect } from 'react';
import './Results.css';
import { navigate } from "@reach/router";
import axios from 'axios';

function Results({ persons }) {
    const [data, setData] = useState(null);
    
    function onClickDetailsButton(personId) {
        navigate(`/details/${personId}`);
    }

    function onClickEditButton(personId) {
        navigate(`/person/${personId}`);
    }

    function onClickDeleteButton(personId) {
        axios.post(`http://apps.loopevo.com/apis/students/delete.php`, {
            id: personId
        })
        .then(function () {
            let newData = data.filter(function( obj ) {
                return Number(obj.id) !== Number(personId);
            });
            setData(newData);
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    useEffect(() => {
        setData(persons)
    }, [persons]);

    if (!data || !data.length) {
        return (
            <h1>There are no results</h1>
        )
    }

    return (
        <table className="table">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Email</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {data && 
                data.map(person => (
                    <tr key={person.id}>
                        <th scope="row">{person.id}</th>
                        <td>{person.first_name}</td>
                        <td>{person.last_name}</td>
                        <td>{person.email}</td>
                        <td className="buttons">
                            <button 
                                type="button" 
                                className="btn btn-success"
                                onClick={() => onClickEditButton(person.id)}>
                                Edit
                            </button>
                            <button 
                                type="button" 
                                className="btn btn-secondary"
                                onClick={() => onClickDeleteButton(person.id)}>
                                Delete
                            </button>
                            <button 
                                type="button" 
                                className="btn btn-primary"
                                onClick={() => onClickDetailsButton(person.id)}>
                                Details
                            </button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

export default Results;