import React from 'react';

function FilterForm({ onSubmit, handleCityChange, handleAgeChange, cities, ages }) {
    return (
        <form onSubmit={onSubmit}>
            <div className="form-group">
                <label htmlFor="city">City:</label>
                <select 
                    className="form-control" 
                    id="citu"
                    onChange={handleCityChange}>
                    {cities.length &&
                    cities.map(city => (
                        <option key={city.id} value={city.id}>
                            {city.name}
                        </option>
                    ))}  
                </select>
            </div>
            <div className="form-group">
                <label htmlFor="age">Age:</label>
                <select 
                    className="form-control" 
                    id="age"
                    onChange={handleAgeChange}>
                    {ages.length &&
                    ages.map(age => (
                        <option key={age} value={age}>
                            {age}
                        </option>
                    ))}
                </select>
            </div>
            <button type="submit" className="btn btn-primary">Search</button>
        </form>
    )
}

export default FilterForm;